// Common elements

.col-3-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 16px;
	padding: 8px 0;
}

.option {
	border-radius: 4px;
	aspect-ratio: 1;
	// border: 2px rgb(215, 215, 215) solid;
	border: 2px white solid;
	padding: 4px;
	position: relative;

	img {
		display: block;
		width: 90%;
		// height: auto;
		aspect-ratio: 1;
		margin: 0 auto;
	}
	.option-name {
		font-size: 14px;
		font-weight: 700;
		text-align: center;
		color: gray;
	}

	&.selected {
		border: 2px #06d6a0 solid;
		.option-name {
			color: black;
		}
	}

	.checkbox {
		position: absolute;
		width: 20px;
		height: 20px;
		font-size: 17px;
		background-color: #06d6a0;
		color: white;
		text-align: center;
		border-radius: 100%;
		top: 4px;
		right: 4px;
	}
}

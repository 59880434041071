.styled-button {
	border: none;
	background: none;

	background-color: #073b4c;
	color: white;
	height: 40px;
	width: 100%;
	border-radius: 8px;

	font-size: 16px;
	font-weight: 700;

	&.secondary {
		background-color: rgb(226, 226, 226);
		color: black;
	}
}
